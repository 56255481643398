import React,{useEffect, useState} from "react"
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { id } from 'date-fns/locale';

const View = ()=>{
    const { invoice } = useParams();
    const [response,setResponse] = useState()
    useEffect(() => {
        const sendPostRequest = async () => {
            const paymentData = {
                invoice: invoice
            };
    
            try {
                const response = await fetch('/api/view-data', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': 'panelJapriPayVersion2'
                    },
                    body: JSON.stringify(paymentData)
                });
                const data = await response.json();
                setResponse(data)
            } catch (error) {
                console.error('Error:', error);
            }
        };
        sendPostRequest()
    }, [invoice]);
    return(
        <div className="flex flex-col h-screen w-full bg-gradient-to-br from-blue-400 to-purple-500 overflow-auto ">
            <div className="lg:mx-auto mx-5 lg:my-12 my-4 rounded-lg lg:w-1/2 w-100 bg-gray-100 flex flex-col p-4">
                <p className="text-xl text-center font-bold mb-5">View Data Pendaftar</p>
                <div className="flex flex-col">
                    <div className="flex justify-between mb-2">
                        <p>Nama Lengkap</p>
                        <p>{response ? response.name : ""}</p>
                    </div>
                    <div className="flex justify-between mb-2">
                        <p>No. Handphone</p>
                        <p>{response ? response.phone : ""}</p>
                    </div>
                    <div className="flex justify-between mb-2">
                        <p>Tempat Lahir</p>
                        <p>{response ? response.tempat_lahir : ""}</p>
                    </div>
                    <div className="flex justify-between mb-2">
                        <p>Tanggal Lahir</p>
                        <p>{response ? format(new Date(response.tanggal_lahir), "dd MMMM yyyy", { locale: id }) : ""}</p>
                    </div>
                    <div className="flex justify-between mb-2">
                        <p>Alamat</p>
                        <p>{response ? response.alamat : ""}</p>
                    </div>
                    <div className="flex justify-between mb-2">
                        <p>Kelurahan</p>
                        <p>{response ? response.kelurahan : ""}</p>
                    </div>
                    <div className="flex justify-between mb-2">
                        <p>Kecamatan</p>
                        <p>{response ? response.kecamatan : ""}</p>
                    </div>
                    <div className="flex justify-between mb-2">
                        <p>Kabupaten</p>
                        <p>{response ? response.kabupaten : ""}</p>
                    </div>
                    <div className="flex justify-between mb-2">
                        <p>Profinsi</p>
                        <p>{response ? response.kabupaten : ""}</p>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <p>Foto KTP</p>
                            <img src={`/berkas/${response ? response.ktp_file : ""}`} alt={response && response.name } className="rounded-lg w-full lg:h-72 mt-1"/>
                        </div>
                        <div>
                            <p>Foto Kartu Keluarga</p>
                            <img src={`/berkas/${response ? response.kk_file : ""}`} alt={response && response.name } className="rounded-lg w-full h-24 lg:h-72 mt-1"/>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}
export default View