import React from "react"

const InputText = ({label,type,placeholder,require,name,value,onChange })=>{
    const classUpload = "block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-blue-500" 
    const classInput = "bg-gray-100 text-gray-700 border border-gray-400 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:outline-blue-500 block w-full p-2" 
    return(
        <div className="my-1">
            <label htmlFor={label} className="block mb-1 text-sm font-medium text-gray-900">
                {label} {require && <span className="ms-2 text-red-500">*</span>}
            </label>
            <input 
                type={type} 
                className={type === 'file' ? classUpload : classInput}
                placeholder={placeholder}
                name={name}
                value={value}
                onChange={onChange}
                required={require} />
        </div>
    )
}
export default InputText