import React, { useState } from "react"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Auth = ()=>{
    const [username,setUsername] = useState("")
    const [password,setPassword] = useState("")
    const ActionLogin = (e) => {
        e.preventDefault();
        if(username === 'admin' && password === 'admin'){
            toast.success('Login successful!', {
                position: "top-right",
                autoClose: 2000,
              });
            const now = new Date().getTime();
            localStorage.setItem('loginTime', now);
            localStorage.setItem("username",username)
            window.location.href = "/"
        }else{
            toast.error('Username atau password Salah', {
                position: "top-right",
                autoClose: 2000,
              });
        }
        setUsername("")
        setPassword("")
    };
    return(
        <div className="h-screen w-full flex justify-center items-center bg-gray-200">
            <div className="flex-col p-3 bg-white rounded-lg lg:w-1/3 w-full mx-6">
                <p className="text-center text-xl font-bold mb-6">Login User</p>
                <form onSubmit={ActionLogin}>
                <div className="mb-5">
                    <label 
                        htmlFor="email" 
                        className="block mb-1 text-sm font-medium text-gray-500">
                            Username
                    </label>
                    <input 
                        type="text"
                        value={username}
                        onChange={(e)=>setUsername(e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-lg block w-full p-2.5 focus:ring-blue-500" 
                        placeholder="username" 
                        required />
                </div>
                <div className="mb-5">
                    <label 
                        htmlFor="email" 
                        className="block mb-1 text-sm font-medium text-gray-500">
                            Password
                    </label>
                    <input 
                        type="password"
                        value={password}
                        onChange={(e)=>setPassword(e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-lg block w-full p-2.5 focus:ring-blue-500" 
                        placeholder="passwprd" 
                        required />
                </div>
                <button 
                    type="submit" 
                    className="w-full text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">
                        Submit
                </button>
                </form>
            </div>
            <ToastContainer />
        </div>
    )
}
export default Auth