import React, { useState, Suspense, lazy } from 'react';
import Sidebar from "./Sidebar";
import { Route, Routes } from 'react-router-dom';
import Loading from '../interfaces/Loading';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Dashboard = lazy(() => import('../interfaces/Dashboard'));
const TopUp = lazy(() => import('../interfaces/TopUp'));
const Members = lazy(() => import('../interfaces/Members'));
const Umroh = lazy(()=> import('../interfaces/Umroh'))

const Layout = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    return (
        <div className="min-h-screen flex bg-gray-100">
            <Sidebar isOpen={isSidebarOpen} toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)} />
            <div className='flex flex-col w-full'>
                <header className='flex justify-between p-4 bg-gray-50'>
                    <div>
                        <p>Dashboard</p>
                    </div>
                </header>
                <main className='flex overflow-auto bg-gray-100 p-5'>
                    <Suspense fallback={<Loading />}>
                        <Routes>
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/topup" element={<TopUp />} />
                            <Route path="/members" element={<Members />} />
                            <Route path="/umroh" element={<Umroh />} />
                        </Routes>
                    </Suspense>
                </main>
            </div>
            <ToastContainer />
        </div>
    );
}

export default Layout;
