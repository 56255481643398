import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const InputanTanggal = ({ handleDateChange, selectedDate }) => {
    return (
        <div className="flex flex-col">
            <label className="block mb-1 text-sm font-medium text-gray-900 ">Tanggal Lahir <span className="ms-2 text-red-500">*</span></label>
            <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                dateFormat="yyyy-MM-dd"
                className="bg-gray-100 text-gray-700 border border-gray-400 text-gray-900 text-sm rounded-md focus:outline-blue-500 block w-full p-2"
                placeholderText="Pilih tanggal"
            />
        </div>
    );
}

export default InputanTanggal;