import { faBriefcase, faClose, faHome, faMoneyBillTrendUp, faPowerOff, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { NavLink } from 'react-router-dom';


const Sidebar = ({ isOpen, toggleSidebar }) => {
  const logOut = ()=>{
    localStorage.clear()
    window.location.href = '/'
  }
  return (
    <div className={`fixed text-white lg:relative lg:flex flex-col w-72 h-screen p-4 bg-gray-700 z-20 transform lg:translate-x-0 ${isOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out`}>
      <FontAwesomeIcon icon={faClose} className='w-7 h-7 lg:hidden absolute top-0 right-0 mr-4 mt-4 border-[1px] border-gray-500 p-1 rounded-lg' onClick={toggleSidebar} />
      <div className='flex mb-5'>
        <p className='text-2xl font-bold text-white'>Panel Japri</p>
      </div>
      <nav className='flex flex-col space-y-1'>
        <NavLink
          to="/app/dashboard"
          className={({ isActive }) =>
            `w-100 flex items-center p-2 border-[2px] rounded-lg ${
              isActive ? 'border-gray-500 bg-gray-600' : 'border-gray-700 hover:border-gray-500 hover:bg-gray-600'
            }`
          }
        >
            <FontAwesomeIcon icon={faHome} className='w-5 h-5 mr-2'/>
            Home
        </NavLink>
        <NavLink
          to="/app/topup"
          className={({ isActive }) =>
            `w-100 flex items-center p-2 border-[2px] rounded-lg ${
              isActive ? 'border-gray-500 bg-gray-600' : 'border-gray-700 hover:border-gray-500 hover:bg-gray-600'
            }`
          }
        >
            <FontAwesomeIcon icon={faMoneyBillTrendUp} className='w-5 h-5 mr-2'/>
            Top Up
        </NavLink>
        <NavLink
          to="/app/members"
          className={({ isActive }) =>
            `w-100 flex items-center p-2 border-[2px] rounded-lg ${
              isActive ? 'border-gray-500 bg-gray-600' : 'border-gray-700 hover:border-gray-500 hover:bg-gray-600'
            }`
          }
        >
            <FontAwesomeIcon icon={faUsers} className='w-5 h-5 mr-2'/>
            Members
        </NavLink>
        <NavLink
          to="/app/umroh"
          className={({ isActive }) =>
            `w-100 flex items-center p-2 border-[2px] rounded-lg ${
              isActive ? 'border-gray-500 bg-gray-600' : 'border-gray-700 hover:border-gray-500 hover:bg-gray-600'
            }`
          }
        >
            <FontAwesomeIcon icon={faBriefcase} className='w-5 h-5 mr-2'/>
            Umroh
        </NavLink>
        <div 
          className='w-100 cursor-pointer flex items-center p-2 border-[2px] rounded-lg border-gray-700 hover:border-gray-500 hover:bg-gray-600'
          onClick={()=>logOut()}
          >
          <FontAwesomeIcon icon={faPowerOff} className='w-5 h-5 mr-2'/>
          LogOut
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
