import InputanTanggal from "./InputanTanggal";
import InputText from "./InputText";

const Inputan = ({ formData, handleInputChange,handleDateChange }) => {
  return (
    <div className="flex flex-col">
      <InputText
        type="text"
        placeholder="Nama Lengkap"
        require={true}
        label="Nama Lengkap"
        name="name"
        value={formData.name}
        onChange={handleInputChange}
      />
      
      <InputText
        type="text"
        placeholder="Tempat Lahir"
        require={true}
        label="Tempat Lahir"
        name="tempat"
        value={formData.tempat}
        onChange={handleInputChange}
      />
      <InputanTanggal selectedDate={formData.tanggal} handleDateChange={handleDateChange} />
      <InputText
        type="text"
        placeholder="Alamat"
        require={true}
        label="Alamat"
        name="alamat"
        value={formData.alamat}
        onChange={handleInputChange}
      />
      <InputText
        type="text"
        placeholder="Kelurahan"
        require={true}
        label="Kelurahan"
        name="kelurahan"
        value={formData.kelurahan}
        onChange={handleInputChange}
      />
      <InputText
        type="text"
        placeholder="Kecamatan"
        require={true}
        label="Kecamatan"
        name="kecamatan"
        value={formData.kecamatan}
        onChange={handleInputChange}
      />
      <InputText
        type="text"
        placeholder="Kabupaten"
        require={true}
        label="Kabupaten"
        name="kabupaten"
        value={formData.kabupaten}
        onChange={handleInputChange}
      />
      <InputText
        type="text"
        placeholder="Provinsi"
        require={true}
        label="Provinsi"
        name="provinsi"
        value={formData.provinsi}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default Inputan;
