import React,{useEffect} from 'react';
import { useParams } from 'react-router-dom';

const decodeBase64 = (encodedData) => {
    try {
        const decodedData = atob(encodedData);
        const params = new URLSearchParams(decodedData);
        const invoice = params.get('invoice');
        const price = params.get('price');
        return { invoice, price };
    } catch (error) {
        console.error("Error decoding Base64:", error);
        return null;
    }
};

const Invoice = () => {
    const { encodedParams } = useParams();
    const data = decodeBase64(encodedParams);
    useEffect(() => {
        const sendPostRequest = async () => {
            const paymentData = {
                invoice: data.invoice,
                amount: Number(data.price)
            };
    
            try {
                const response = await fetch('/form/payment', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': 'your_secret_api_key'  // Header API key
                    },
                    body: JSON.stringify(paymentData)
                });
                const data = await response.json();
                window.location.href = data
            } catch (error) {
                console.error('Error:', error);
            }
        };
        sendPostRequest()
    }, [data]);
    if (!data) {
        return <p>Error: Cannot decode data</p>;
    }
    
    
    return (
        <div className="invoice-container">
            
        </div>
    );
};

export default Invoice;
