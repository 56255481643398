import React, { useState } from 'react';
import Inputan from './Inputan'
import InputText from './InputText'
import InputanPenjamin from './InputanPenjamin'
import { ToastContainer, toast } from 'react-toastify';
import Loading from '../interfaces/Loading'

const FormPendaftaran = () => {
    const [formData, setFormData] = useState({
        name: "",
        tempat: "",
        tanggal: "",
        alamat: "",
        kelurahan: "",
        kecamatan: "",
        kabupaten: "",
        provinsi: "",
        penjamin_name: "",
        penjamin_tempat: "",
        penjamin_tanggal: "",
        penjamin_alamat: "",
        referral: localStorage.getItem("reff"),
    });
    const [files, setFiles] = useState({ ktp: null, kk: null });
    const [isSameAsAbove, setIsSameAsAbove] = useState(false);
    const [showPenjaminForm, setShowPenjaminForm] = useState(false);
    const [loading,setLoading] = useState(false)
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleDateChange = (date) => {
        setFormData((prevData) => ({
            ...prevData,
            tanggal: date.toString(),
        }));
    };

    const handleDatePenjaminChange = (date) => {
        setFormData((prevData) => ({
            ...prevData,
            penjamin_tanggal: date,
        }));
    };

    const handleCheckboxChange = (type) => {
        if (type === "same") {
            setIsSameAsAbove(true);
            setShowPenjaminForm(false);
        } else {
            setIsSameAsAbove(false);
            setShowPenjaminForm(true);
        }
    };

    const handleFileChange = (event) => {
        const { name, files: [file] } = event.target;
        setFiles((prevFiles) => ({ ...prevFiles, [name]: file }));
    };
    const InvoiceNumber = () => {
        const prefix = 'INV-'; // Prefix untuk invoice
        const randomNum = Math.floor(10000000 + Math.random() * 90000000); // Angka acak 6 digit
        return prefix + randomNum;
    };
    console.log(localStorage.getItem("product"))
    const handleSubmit = async () => {
        setLoading(true)
        const dataToSend = {
            ...formData,
            penjamin_name: isSameAsAbove ? formData.name : formData.penjamin_name,
            penjamin_tempat: isSameAsAbove ? formData.tempat : formData.penjamin_tempat,
            penjamin_tanggal: isSameAsAbove ? formData.tanggal : formData.penjamin_tanggal,
            penjamin_alamat: isSameAsAbove ? formData.alamat : formData.penjamin_alamat,
        };

        const formDataToSend = new FormData();
        for (const key in dataToSend) {
            formDataToSend.append(key, dataToSend[key]);
        }
        const invoice = InvoiceNumber()
        formDataToSend.append('ktp', files.ktp);
        formDataToSend.append('kk', files.kk);
        formDataToSend.append('product', "Umroh");
        formDataToSend.append('keterangan', localStorage.getItem("product"));
        formDataToSend.append('invoice', invoice);
        try {
            const response = await fetch('/form/registered', {
                method: 'POST',
                body: formDataToSend,
                headers: {
                    "x-api-key": 'panelJapriPayVersion2'
                }
            });

            if (!response.ok) {
                console.log("Terjadi kesalahan")
            }

            const result = await response.json();
            if (result.error) {
                toast.error(result.error);
            } else {
                toast.success(result.message);
                window.location.href='/confrm'
            }
            
            setLoading(false)
        } catch (error) {
            toast.error(error.message);
        }
        localStorage.setItem('reff',"");
        localStorage.setItem('product',"");
    };
    const goBack = () => {
        window.location.reload();
    }
    if(loading){
        return(<Loading/>)
    }
    return (
        <div className="h-screen overflow-auto w-full bg-gradient-to-br from-blue-200 to-purple-400 flex flex-col">
            <div className="lg:mx-auto mx-5 lg:my-12 my-4 rounded-lg lg:w-1/2 w-100 flex flex-col p-4 bg-gray-50">
                <p className="text-center font-bold text-blue-400 mb-2 lg:text-2xl text-xl">Form Pendaftaran</p>
                <Inputan formData={formData} handleInputChange={handleInputChange} handleDateChange={handleDateChange} />
                <InputText
                    type="file"
                    require={true}
                    label="Foto KTP"
                    name="ktp"
                    onChange={handleFileChange}
                />
                <InputText
                    type="file"
                    require={true}
                    label="Foto KK"
                    name="kk"
                    onChange={handleFileChange}
                />
                <p className="mt-3 mb-2 text-sm font-semibold text-gray-600">Data Penjamin</p>
                <div className="flex items-center mb-2">
                    <input
                        id="same-as-above-checkbox"
                        type="checkbox"
                        checked={isSameAsAbove}
                        onChange={() => handleCheckboxChange("same")}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                    />
                    <label className="ms-2 text-xs font-medium text-gray-900 dark:text-gray-300">
                        Sama dengan data diatas
                    </label>
                </div>
                <div className="flex items-center mb-2">
                    <input
                        id="input-penjamin-checkbox"
                        type="checkbox"
                        checked={showPenjaminForm}
                        onChange={() => handleCheckboxChange("input")}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:outline-blue-500"
                    />
                    <label className="ms-2 text-xs font-medium text-gray-900 dark:text-gray-300">
                        Isi data penjamin
                    </label>
                </div>
                {showPenjaminForm && <InputanPenjamin formData={formData} handleInputChange={handleInputChange} handleDatePenjaminChange={handleDatePenjaminChange} />}
                <div className='grid grid-cols-2 w-full gap-6'>
                    <div onClick={goBack} className="w-full cursor-pointer text-center bg-gradient-to-br from-red-700 to-red-300 text-white py-1.5 rounded-lg mt-6">
                        Kembali
                    </div>
                    <div onClick={handleSubmit} className="w-full cursor-pointer text-center bg-gradient-to-br from-green-200 to-green-700 text-white py-1.5 rounded-lg mt-6">
                        Submit
                    </div>
                </div>

            </div>
            <ToastContainer />
        </div>
    )
}
export default FormPendaftaran