import InputText from "./InputText";
import InputanTanggal from "./InputanTanggal";
const InputanPenjamin = ({ formData, handleInputChange,handleDatePenjaminChange }) => {
  return (
    <div className="flex flex-col">
      <InputText
        type="text"
        placeholder="Nama Penjamin"
        require={true}
        label="Nama Penjamin"
        name="penjamin_name"
        value={formData.penjamin_name}
        onChange={handleInputChange}
      />
      <InputText
        type="text"
        placeholder="Tempat Lahir Penjamin"
        require={true}
        label="Tempat Lahir Penjamin"
        name="penjamin_tempat"
        value={formData.penjamin_tempat}
        onChange={handleInputChange}
      />
      <InputanTanggal selectedDate={formData.penjamin_tanggal} handleDateChange={handleDatePenjaminChange} />
      <InputText
        type="text"
        placeholder="Alamat Penjamin"
        require={true}
        label="Alamat Penjamin"
        name="penjamin_alamat"
        value={formData.penjamin_alamat}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default InputanPenjamin;
