import React,{Suspense,useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route,Navigate } from 'react-router-dom';
import Loading from './interfaces/Loading';
import Layout from './components/Layout';
import Auth from './interfaces/Auth';
import Confrm from './umroh/Confrm';
import Umroh from './umroh';
import Invoice from './interfaces/Invoice';
import View from './umroh/View';
const AUTO_LOGOUT_TIME = 60 * 60 * 1000;

function RequireAuth({ children }) {
  const TOKEN = localStorage.getItem("username");
  const PUBLIC_ROUTES = ["login", "forgot-password", "register", "documentation"];
  const isPublicPage = PUBLIC_ROUTES.some(r => window.location.href.includes(r));

  if (!TOKEN && !isPublicPage) {
    return <Navigate to="/auth" replace />;
  }
  return children;
}

function App() {
  useEffect(() => {
    const checkSession = () => {
      const loginTime = localStorage.getItem('loginTime');
      const now = new Date().getTime(); 
      if (loginTime && now - loginTime > AUTO_LOGOUT_TIME) {
        handleLogout();
      }
    };
    const interval = setInterval(checkSession, 1000);
    return () => clearInterval(interval);
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/auth";
  };
  return (
    <Router>
      <Suspense fallback={<Loading/>}>
        <Routes>
          <Route path="/auth" element={<Auth />} />
          <Route path="/umroh" element={<Umroh />} />
          <Route path="/confrm" element={<Confrm />} />
          <Route path="/invoice/:encodedParams" element={<Invoice />} />
          <Route path="/view/:invoice" element={<View />} />
          <Route path="/app/*" element={
              <RequireAuth>
                <Layout />
              </RequireAuth>
            }/>
          <Route path="*" element={<Navigate to={localStorage.getItem("username") ? "/app/dashboard" : "/auth"} replace />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
