import { faRefresh } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Loading = ()=>{
    return(
        <div className="fixed inset-0 bg-gray-900 bg-opacity-20 flex justify-center items-center">
            <FontAwesomeIcon icon={faRefresh} className="fa-spin w-24 h-24 text-blue-500"/>
        </div>
    )
}
export default Loading