import React from "react"
import logo from '../logoJapri.png'
const Confrm = ()=>{
    return(
        <div className="flex flex-col h-screen w-full bg-gradient-to-br from-blue-400 to-purple-500 overflow-auto ">
                <div className="lg:mx-auto mx-5 lg:my-12 my-4 rounded-lg lg:w-1/2 w-100 bg-gray-100 flex flex-col p-4 justify-center items-center">
                    <img src={logo} alt="PT Japri Pay Nusantara" className="mb-4 w-24 mc-auto"/>
                    <p className="text-center text-xl text-blue-500 font-bold">Pendaftaran Umroh</p>
                    <p className="mt-4 text-center">Pendaftaran anda sudah kami terima, silahkan tunggu konfirmasi dari kemai</p>
                </div>
            </div>
    )
}
export default Confrm