import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FormatRupiah } from "@arismun/format-rupiah";
import FormPendaftaran from './FormPendaftaran'
const Umroh = ()=>{
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [daftar,setDaftar] = useState(false)
    const location = useLocation();
    
    const queryParams = new URLSearchParams(location.search);
    var reffValue = queryParams.get('reff');
    const decryptXOR = (encryptedData, secretKey) => {
        return encryptedData ^ secretKey;
    };
    const referral = decryptXOR(Number(reffValue),123456789)
    localStorage.setItem("reff",referral)
    const productTitle = queryParams.get('product');
    localStorage.setItem("product",decodeURIComponent(productTitle))
    useEffect(() => {
        const fetchPackages = async () => {
            try {
                const response = await fetch('https://raw.githubusercontent.com/japricorp/japripay-v2/refs/heads/main/umroh.json');
                const result = await response.json();
                
                const matchingPackage = result.data.find(pkg => pkg.title === decodeURIComponent(productTitle));
                if (matchingPackage) {
                    setSelectedPackage(matchingPackage);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchPackages();
    }, [productTitle]); 
    const showForm = ()=>{
        setDaftar(true)
    }
    if(daftar){
        return(<FormPendaftaran/>)
    }else{
        return(
            <div className="flex flex-col h-screen w-full bg-gradient-to-br from-blue-400 to-purple-500 overflow-auto ">
                <div className="lg:mx-auto mx-5 lg:my-12 my-4 rounded-lg lg:w-1/2 w-100 bg-gray-100 flex flex-col">
                    {selectedPackage ? (
                        <>
                            <img src={selectedPackage.image} alt={selectedPackage.title} className="rounded-tr-lg rounded-tl-lg w-full" />
                            <div className="p-4">
                                <p className="text-md font-bold">{selectedPackage.title}</p>
                                <p className="text-xs my-2">{selectedPackage.keterangan || 'Keterangan tidak tersedia'}</p>
                                <p className="text-md font-bold text-purple-600">
                                    <FormatRupiah value={selectedPackage.price} />
                                </p>
                                <div 
                                    onClick={() => showForm()} 
                                    className="w-full cursor-pointer text-center bg-gradient-to-br from-green-200 to-green-700 text-white py-1.5 rounded-lg mt-6"
                                >
                                    Daftar
                                </div>
                            </div>
                        </>
                    ) : (
                        <p className='p-4 text-red-500 font-semibold'>Paket yang anda pilih tidak ditemukan</p>
                    )}
                    
                </div>
            </div>
        )
    }
}
export default Umroh